'use strict';
jQuery(document).ready(function($){
  $('.slider').slick({
    autoplay:true,
	  fade:true,
    arrows:false,
	  autoplaySpeed: 500,
	  speed:1000,
	  pauseOnFocus:false,
	  pauseOnHover:false
  });
    $('.news-slider').slick({
        infinite: true,
        autoplay: false,
        speed: 1000,
        slidesToShow: 3,
        dots:false,
        arrows:true,
        autoplaySpeed: 3000,
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              arrows:false,
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              centerMode: true,
              centerPadding: '40px',
            }
          },
        ]
      });

     
});